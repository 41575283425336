import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TemporizadormapService {
  private diferenciaTiempoSec: number = 0;
  private diffDate: string = '0 min 0 sec';
  private destroy$ = new Subject<void>();
  public diffDateSubject = new Subject<string>();
  private destroyed = false;

  constructor(private storage: Storage) {}

  startTracking(): void {
    timer(0, 5000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (!this.destroyed) {
          await this.CheckTime();
          this.emitDiffDatedefault();
        }
      });
  }

  public ponerdestroyedFalse()
  {
    this.destroyed = false;
  }

  stopTracking(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroyed = true;
    this.diffDate = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDiffDateObservable(): Observable<string> {
     return this.diffDateSubject.asObservable();
   }

  public emitDiffDatedefault(): void {
    this.diffDateSubject.next(this.diffDate);
  }

  public emitDiffDate(diffDate): void {
    this.diffDateSubject.next(diffDate);
  }

  async resetDiffDate(): Promise<void> {

  await this.storage.set('mapTime', new Date().toString());
  this.stopTracking();
}

  private async CheckTime(): Promise<void> {
 
    const lastDate = new Date(await this.storage.get('mapTime'));
    const actualDate = new Date();
    lastDate.setTime(lastDate.getTime() + 100000);
  
    const diferenciaTiempoMs = lastDate.getTime() - actualDate.getTime();
    this.diferenciaTiempoSec = Math.floor(diferenciaTiempoMs / 1000);
    

    const m = Math.floor(this.diferenciaTiempoSec % 3600 / 60);
    const s = Math.floor(this.diferenciaTiempoSec % 3600 % 60);

    this.diffDate = m + ' min ' + s + ' sec';

    if (this.diferenciaTiempoSec <= 0) {
      // this.diffDate = null; // Ponerlo de nuevo a null cuando llegue a 0
      // this.diferenciaTiempoSec = null;
    }
  }

  public getDiffDate(): number {

    var tiempoEnSegundos = 0;
    if (this.diffDate !== null) 
    {
      const tiempoArray = this.diffDate.split(' '); // Divide la cadena en un array ["X", "min", "X", "sec"]
      
      const minutos = parseInt(tiempoArray[0]); // Convierte el primer elemento a un número
      const segundos = parseInt(tiempoArray[2]); // Convierte el tercer elemento a un número
    
      tiempoEnSegundos = minutos * 60 + segundos;
    }
    return tiempoEnSegundos;
  }


}
