import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import User from 'src/app/interfaces/user.interface';
import {OrderService} from 'src/app/services/order/order.service';
import {MenuController, ToastController} from '@ionic/angular';
import {AuthService} from 'src/app/services/auth/auth.service';
import {LoadNotificacionService} from '../../../services/components/load-notificacion.service';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';
import Order from '../../../interfaces/order.interface';
import {ApiService} from '../../../services/api/api.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';
import { MidnightService } from 'src/app/services/midnight-service/midnight-service.service';
import { Subject, Subscription, interval, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, OnDestroy {

    user: User;
    searchTerm: Order;
    private destroy$ = new Subject<void>();
    orderListSubscription: Subscription;
    

    constructor(
        private _orderService: OrderService,
        private _apiService: ApiService,
        private authService: AuthService,
        public localStorageService: LocalStorageService,
        private menuCtrl: MenuController,
        private translationService: TranslateService,
        private toastController: ToastController,
        private _loaderService: LoadNotificacionService,
        private searchService: SearchService,
        private themeService: ThemeServiceService,
        private midnightService:MidnightService,
        private changeDetector: ChangeDetectorRef) {
            
    }

    async ngOnInit() {
        this.user = this.authService.getUser();
        this.orderListSubscription = this.localStorageService.getOrderListObservable().subscribe((newOrderList) => {
            // Actualizar la lista local en respuesta a cambios
            this.localStorageService.saveOrderList(newOrderList);
            this.localStorageService.check = true;
          });
        //this.localStorageService.check = false;
        this.searchService.searchTerm$.subscribe(async (searchTerm) => {
             this.searchTerm = searchTerm;
            const Ordelist = this.localStorageService.getOrderList();
            // // this.localStorageService.check == false
            if (searchTerm && Ordelist!= null) {
                this._apiService.getAllOrders(searchTerm.id, searchTerm.id_truck).subscribe(it => {
                    const filteredOrders = it.data.orders.filter(order => order.status === 0);
                    const index = filteredOrders.findIndex(order => order.id === searchTerm.id);
                    // Capitalizar la primera letra de la propiedad en filteredOrders
                    if (filteredOrders.length > 0) {
                        filteredOrders.forEach(order => {
                            order.city = order.city.charAt(0).toUpperCase() + order.city.slice(1);
                          });
                    }
                    this.localStorageService.saveOrderList(filteredOrders.slice(0 ,index +1))
                    this.changeDetector.detectChanges(); // Notificar a Angular de los cambios después de completar la operación de almacenamiento
                });
            }
        });
        this.midnightService.checkIf24HoursPassed();

    // Realizar la acción repetitiva cada 10 segundos
    if(this.searchTerm != null) {
      
      interval(10000)
      .pipe(
        switchMap(() => this._apiService.getAllOrders( this.searchTerm.id,  this.searchTerm.id_truck)), // Reemplaza apiCall() con tu método de llamada a la API
        takeUntil(this.destroy$) // Cancelar la suscripción cuando el componente se destruye
      )
      .subscribe((response) => {
        
        const filteredOrders = response.data.orders.filter(order => order.status === 0);
        const index = filteredOrders.findIndex(order => order.id === this.searchTerm.id);
        if (filteredOrders.length > 0) {
          filteredOrders.forEach(order => {
              order.city = order.city.charAt(0).toUpperCase() + order.city.slice(1);
            });
        }
        if(this.localStorageService.check == false)
        {
          this.localStorageService.saveOrderList(filteredOrders.slice(0 ,index +1))
          this.changeDetector.detectChanges(); // Notificar a Angular de los cambios después de completar la operación de almacenamiento
        }
      
      });

    
    }
    
    const time = localStorage.getItem('firstTime');
    if(time == null) {
      localStorage.setItem('firstTime', '1');
      window.location.reload();
    }
      
       
    }

    ngOnDestroy(): void {
        this.user = null;
        this.localStorageService.saveOrderList(null)
        this.searchTerm = null;
        this.destroy$.next();
        this.destroy$.complete();
    }

      // Método para obtener el valor de DarkTheme del servicio
   public  esdark(): boolean {
    return this.themeService.isDarkTheme();
    }

}
