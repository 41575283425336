import { Component, OnInit } from '@angular/core';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
  selector: 'app-change-theme',
  templateUrl: './change-theme.component.html',
  styleUrls: ['./change-theme.component.scss'],
})
export class ChangeThemeComponent implements OnInit  {

  // Propiedad para almacenar el estado del tema
  isDarkTheme: boolean;

  constructor(private themeService: ThemeServiceService) {
    this.themeService.getIsDarkModeObservable().subscribe((isDarkMode) => {
      this.isDarkTheme = isDarkMode;
    });
    this.themeService.setDarkMode(this.isDarkTheme);
      
   }

   ngOnInit(): void {

    this.themeService.getDarkMode().then((darkModeValue) => {
      this.isDarkTheme = darkModeValue;
    });
    
     
   }

  toggleDarkMode() {
    this.themeService.toggleDarkMode();
    // Actualiza la propiedad isDarkTheme después de cambiar el tema
  }

   // Método para obtener el valor de DarkTheme del servicio
   public  esdark(): boolean {
     return this.isDarkTheme;
   }


}


