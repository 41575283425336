import {Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import Swal, {SweetAlertResult} from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Apiv2Service } from '../v2/apiv2.service';
import { Personalization } from 'src/app/interfaces/personalization.interface';
import { PersonalizationService } from '../personalization/personalization.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private _translateService: TranslateService,
        private _nav: NavController,
        private storage: Storage,
        private api: Apiv2Service,
        private personalizationService: PersonalizationService
    ) {
    }



    public presentAlertReprogramado( message: string, params?: Object) {

       return  Swal.fire({
            iconHtml: '<img src="/assets/icon/reprogramado-cancelado.png" alt="">', 
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            text: this._translateService.instant(message, params),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }

            
        })

    }

    public presentAlertOrderDelivered(message: string, orderCode: number, params?: Object)
    {

            // Agregar orderCode al objeto params
            params = { ...params, orderCode: orderCode };
    
            // Obtener el mensaje traducido
            const translatedMessage = this._translateService.instant(message, params);
    
            // Agregar el orderCode al mensaje
            const messageWithOrderCode = `${translatedMessage} - <strong>${orderCode}</strong>`;
            const text = this._translateService.instant(message, params);
            const checkRating = this.api.get('custom/checkRating/' + orderCode).subscribe((data: any) => {
                if(data.data !== null) {
                    const html = ` 
                    <div class="rating">
                        <h3>${messageWithOrderCode}</h3>
                    </div>
                    `;

                    return  Swal.fire({
                        iconHtml: '<img src="/assets/icon/pedido_entregado.png" alt="">', 
                        customClass: {
                            container: 'container-class',
                            title: 'title-class',
                            popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                            icon: 'custom-swal-icon',
                        },
                        backdrop: false,
                        showCancelButton: false, // Desactiva el botón de cancelar
                        showConfirmButton: false, // Desactiva el botón de confirmar
                        showCloseButton: true, // Muestra el botón de cerrar
                        width: "250px",
                        html: html,
                        didOpen: () => {
                            const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                            const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                            if (swalPopup) {
                                swalPopup.style.color = '#061826';
                            }
                            if(swalicon)
                            {
                                swalicon.style.border= 'none' ;
                            }
                        }
            
                        
                    })
                } else {
                    const html = ` 
                    <div class="rating">
                        <h3>${text}</h3>
                        <input class="d-none" type="radio" id="good-icon" name="icons" value="good-icon">
                        <label for="good-icon"><img name="checkmark-circle" id="good-icon-img" src="/assets/rating/good.png"></label>
                        <input class="d-none" type="radio" id="regular-icon" name="icons" value="regular-icon">
                        <label for="regular-icon"><img name="checkmark-circle" id="regular-icon-img" src="/assets/rating/regular.png"></label>
                        <input class="d-none" type="radio" id="bad-icon" name="icons" value="bad-icon">
                        <label for="bad-icon"><img name="checkmark-circle" id="bad-icon-img" src="/assets/rating/bad.png"></label>
                        <textarea rows="4" type="text" id="comment" name="comment" placeholder="Comentarios"></textarea>
                        <button id="send-button" class="white-outline mt-1">Enviar</button>
                    </div>
                    `;

                    return  Swal.fire({
                        iconHtml: '<img src="/assets/icon/pedido_entregado.png" alt="">', 
                        customClass: {
                            container: 'container-class',
                            title: 'title-class',
                            popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                            icon: 'custom-swal-icon',
                        },
                        backdrop: false,
                        showCancelButton: false, // Desactiva el botón de cancelar
                        showConfirmButton: false, // Desactiva el botón de confirmar
                        showCloseButton: true, // Muestra el botón de cerrar
                        width: "250px",
                        html: html,
                        didOpen: () => {
                            const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                            const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                            if (swalPopup) {
                                swalPopup.style.color = '#061826';
                            }
                            if(swalicon)
                            {
                                swalicon.style.border= 'none' ;
                            }

                            document.getElementById("good-icon-img")?.addEventListener("click", () => {

                                // remove checked class from all images
                                const images = document.querySelectorAll("img.checked") as NodeListOf<HTMLImageElement>;
                                images.forEach(image => {
                                    image.classList.remove("checked");
                                });

                                const image = document.getElementById("good-icon-img") as HTMLImageElement;
                                image.classList.add("checked");
                            });

                            document.getElementById("regular-icon-img")?.addEventListener("click", () => {

                                // remove checked class from all images
                                const images = document.querySelectorAll("img.checked") as NodeListOf<HTMLImageElement>;
                                images.forEach(image => {
                                    image.classList.remove("checked");
                                });

                                const image = document.getElementById("regular-icon-img") as HTMLImageElement;
                                image.classList.add("checked");
                            });

                            document.getElementById("bad-icon-img")?.addEventListener("click", () => {

                                // remove checked class from all images
                                const images = document.querySelectorAll("img.checked") as NodeListOf<HTMLImageElement>;
                                images.forEach(image => {
                                    image.classList.remove("checked");
                                });

                                const image = document.getElementById("bad-icon-img") as HTMLImageElement;
                                image.classList.add("checked");
                            });



                            document.getElementById("send-button")?.addEventListener("click", () => {
                                const selectedIcon = document.querySelector('input[name="icons"]:checked');
                                const value = (selectedIcon as HTMLInputElement)?.value || null;

                                if(!value) {
                                    alert("Seleccione una opción");
                                    return;
                                }
                                let rating;
                                switch(value) {
                                    case 'good-icon':
                                        rating = 3;
                                        break;
                                    case 'regular-icon':
                                        rating = 2;
                                        break;
                                    case 'bad-icon':
                                        rating = 1;
                                        break;
                                    default:
                                        rating = 0;
                                        break;
                                }
                                const comment = document.getElementById("comment") as HTMLInputElement;
                                const valueComment = comment ? comment.value : null;
                                const orderId = orderCode.toString();
                                this.sendResult(orderId, rating, valueComment);  
                            });
                        }
            
                        
                    })
                }
            });

        }


        public presentAlertOrderGreatDelivered(message: string, order: string, params?: Object,)
        {
            const text = this._translateService.instant(message, params);
            const checkRating = this.api.get('custom/checkRating/' + order).subscribe((data: any) => {
                if(data.data.id) {
                    const html = ` 
                    <div class="rating">
                        <h3>${text}</h3>
                    </div>
                    `;

                    return  Swal.fire({
                        iconHtml: '<img src="/assets/icon/pedido_entregado.png" alt="">', 
                        customClass: {
                            container: 'container-class',
                            title: 'title-class',
                            popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                            icon: 'custom-swal-icon',
                        },
                        backdrop: false,
                        showCancelButton: false, // Desactiva el botón de cancelar
                        showConfirmButton: false, // Desactiva el botón de confirmar
                        showCloseButton: true, // Muestra el botón de cerrar
                        width: "250px",
                        html: html,
                        didOpen: () => {
                            const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                            const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                            if (swalPopup) {
                                swalPopup.style.color = '#061826';
                            }
                            if(swalicon)
                            {
                                swalicon.style.border= 'none' ;
                            }
                        }
            
                        
                    })
                } else {
                    const html = ` 
                    <div class="rating">
                        <h3>${text}</h3>
                        <input class="d-none" type="radio" id="good-icon" name="icons" value="good-icon">
                        <label for="good-icon"><img name="checkmark-circle" id="good-icon-img" src="/assets/rating/good.png"></label>
                        <input class="d-none" type="radio" id="regular-icon" name="icons" value="regular-icon">
                        <label for="regular-icon"><img name="checkmark-circle" id="regular-icon-img" src="/assets/rating/regular.png"></label>
                        <input class="d-none" type="radio" id="bad-icon" name="icons" value="bad-icon">
                        <label for="bad-icon"><img name="checkmark-circle" id="bad-icon-img" src="/assets/rating/bad.png"></label>
                        <textarea rows="4" type="text" id="comment" name="comment" placeholder="Comentarios"></textarea>
                        <button id="send-button" class="white-outline mt-1">Enviar</button>
                    </div>
                    `;

                    return  Swal.fire({
                        iconHtml: '<img src="/assets/icon/pedido_entregado.png" alt="">', 
                        customClass: {
                            container: 'container-class',
                            title: 'title-class',
                            popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                            icon: 'custom-swal-icon',
                        },
                        backdrop: false,
                        showCancelButton: false, // Desactiva el botón de cancelar
                        showConfirmButton: false, // Desactiva el botón de confirmar
                        showCloseButton: true, // Muestra el botón de cerrar
                        width: "250px",
                        html: html,
                        didOpen: () => {
                            const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                            const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                            if (swalPopup) {
                                swalPopup.style.color = '#061826';
                            }
                            if(swalicon)
                            {
                                swalicon.style.border= 'none' ;
                            }

                            document.getElementById("good-icon-img")?.addEventListener("click", () => {

                                // remove checked class from all images
                                const images = document.querySelectorAll("img.checked") as NodeListOf<HTMLImageElement>;
                                images.forEach(image => {
                                    image.classList.remove("checked");
                                });

                                const image = document.getElementById("good-icon-img") as HTMLImageElement;
                                image.classList.add("checked");
                            });

                            document.getElementById("regular-icon-img")?.addEventListener("click", () => {

                                // remove checked class from all images
                                const images = document.querySelectorAll("img.checked") as NodeListOf<HTMLImageElement>;
                                images.forEach(image => {
                                    image.classList.remove("checked");
                                });

                                const image = document.getElementById("regular-icon-img") as HTMLImageElement;
                                image.classList.add("checked");
                            });

                            document.getElementById("bad-icon-img")?.addEventListener("click", () => {

                                // remove checked class from all images
                                const images = document.querySelectorAll("img.checked") as NodeListOf<HTMLImageElement>;
                                images.forEach(image => {
                                    image.classList.remove("checked");
                                });

                                const image = document.getElementById("bad-icon-img") as HTMLImageElement;
                                image.classList.add("checked");
                            });



                            document.getElementById("send-button")?.addEventListener("click", () => {
                                const selectedIcon = document.querySelector('input[name="icons"]:checked');
                                const value = (selectedIcon as HTMLInputElement)?.value || null;

                                if(!value) {
                                    alert("Seleccione una opción");
                                    return;
                                }
                                let rating;
                                switch(value) {
                                    case 'good-icon':
                                        rating = 3;
                                        break;
                                    case 'regular-icon':
                                        rating = 2;
                                        break;
                                    case 'bad-icon':
                                        rating = 1;
                                        break;
                                    default:
                                        rating = 0;
                                        break;
                                }
                                const comment = document.getElementById("comment") as HTMLInputElement;
                                const valueComment = comment ? comment.value : null;

                                this.sendResult(order, rating, valueComment);
                            });
                        }
            
                        
                    })
                }
            });



            
              
        }
        
    async sendResult(order: string, number: string, comment: string) {
        const user = await this.storage.get('user');
        const company = await this.storage.get('company');
        const rating = number;
        const order_id = order;

        const body = {
            user_email: user.email,
            order_id,
            rating,
            comment
        }

        this.api.post('rating/create',body).subscribe((data: any) => {
            if(data.status === 200) {
                const button = document.getElementsByClassName("swal2-close")[0] as HTMLElement;
                button.click();
            }
        });
 
    }

    public presentAlertOrderNextDay( message: string, orderCode: string, params?: Object) {
        // Agregar orderCode al objeto params
        params = { ...params, orderCode: orderCode };

        // Obtener el mensaje traducido
        const translatedMessage = this._translateService.instant(message, params);

        // Agregar el orderCode al mensaje
        const messageWithOrderCode = `${translatedMessage} - <strong>${orderCode}</strong>`;
    
        return Swal.fire({
            iconHtml: '<img src="/assets/icon/reprogramado-cancelado.png" alt="">', 
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            html: messageWithOrderCode,
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        });
    }

      public presentAlertError( message: string, params?: Object) {
    
          return Swal.fire({
              iconHtml: '<img src="/assets/icon/cancelado.png" alt="">',
              customClass: {
                  container: 'container-class',
                  title: 'title-class',
                  popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
              },
              backdrop: false,
              showCancelButton: false, // Desactiva el botón de cancelar
              showConfirmButton: false, // Desactiva el botón de confirmar
              showCloseButton: true, // Muestra el botón de cerrar
              text: this._translateService.instant(message, params),
              width: "250px",
              didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
          });
      }


      public presentAlertGreat( message: string, params?: Object) {

        return  Swal.fire({
             iconHtml: '<img src="/assets/icon/checkmark.png" alt="">', 
             customClass: {
                 container: 'container-class',
                 title: 'title-class',
                 popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
             },
             backdrop: false,
             showCancelButton: false, // Desactiva el botón de cancelar
             showConfirmButton: false, // Desactiva el botón de confirmar
             showCloseButton: true, // Muestra el botón de cerrar
             width: "350px",
             text: this._translateService.instant(message, params),
             didOpen: () => {
                 const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                 const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                 if (swalPopup) {
                     swalPopup.style.color = '#061826';
                   }
                 if(swalicon)
                 {
                     swalicon.style.border= 'none' ;
                     
                 }
             }
 
             
         })
 
     }

     public presentAlertGreatSmall( message: string, params?: Object) {

        return  Swal.fire({
             iconHtml: '<img src="/assets/icon/checkmark.png" alt="">', 
             customClass: {
                 container: 'container-class',
                 title: 'title-class',
                 popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
             },
             backdrop: false,
             showCancelButton: false, // Desactiva el botón de cancelar
             showConfirmButton: false, // Desactiva el botón de confirmar
             showCloseButton: true, // Muestra el botón de cerrar
             width: "250px",
             text: this._translateService.instant(message, params),
             didOpen: () => {
                 const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                 const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                 if (swalPopup) {
                     swalPopup.style.color = '#061826';
                   }
                 if(swalicon)
                 {
                     swalicon.style.border= 'none' ;
                     
                 }
             }
 
             
         })
 
     }
    

    //TODO order-list DRIVER
    public restAlert() {

        return Swal.fire({
            title: this._translateService.instant('ALERTS.resting_title'),
            icon: 'warning',
            iconHtml: '<img src="/assets/icon/40x40.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                icon: 'disable-border'
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            text: this._translateService.instant('ALERTS.resting'),
        })

    }
    public DriverInactiveAlert(params?: Object)
    {
        return Swal.fire({
            iconHtml: '<img src="/assets/icon/acceso_prohibido.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            text: this._translateService.instant('ALERTS.signInError.user_message',params),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        })

    }

    public companyInactiveAlert( params?: Object) {

        return Swal.fire({
            iconHtml: '<img src="/assets/icon/acceso_prohibido.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            text: this._translateService.instant('ALERTS.signInError.message_company',params),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        })

    }

    //TODO Driver order-list
    public truckNoAvailableAlert() {

        return Swal.fire({
            title: this._translateService.instant('ALERTS.resting_no_truck'),
            icon: 'warning',
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            backdrop: false,
            text: this._translateService.instant('ALERTS.truck_no_available'),
        })

    }

    public truckNotOnline() {

        return Swal.fire({
            iconHtml: '<img src="/assets/icon/inicio_ruta.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                icon: 'custom-swal-icon',
            },
            backdrop: false,
            width: "250px",
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            text: this._translateService.instant('ALERTS.truck_not_online'),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        })

    }

    

    public logOutAlert(message: string) {
        const personalization: Personalization = this.personalizationService.getPersonalization();
        return Swal.fire({
            text: this._translateService.instant(message),
            html: `<ion-icon name="power"  style="color:${personalization.primary_color}; margin-bottom: 10px;"></ion-icon><br>${this._translateService.instant(message)}`,
            showCancelButton: true,
            confirmButtonColor: personalization.primary_color,
            cancelButtonColor: '#1E2F3B',
            width: "250px",
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no'),
            backdrop: false,

        })

    }

    //TODO map-driver.componente
    public showConfirmAlert(loaderText: string) {
        return Swal.fire({
            icon: 'success',
            backdrop: false,
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            confirmButtonText: this._translateService.instant('PLAN.PAYMENT.button'),
            confirmButtonColor: '#16de4b',
            text: this._translateService.instant(loaderText)
        })
    }

    //TODO map-driver
    public nextDayAlert(loaderText: string): Promise<SweetAlertResult> {
        return Swal.fire({
            text: this._translateService.instant(loaderText),
            icon: 'warning',
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            showCancelButton: true,
            backdrop: false,
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no'),
            cancelButtonColor: '#eb3936',
            confirmButtonColor: '#16de4b',
        })
    }

    // TODO map-diver
    public deleteAlert(loaderText: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this._translateService.instant(loaderText),
            text: this._translateService.instant('ALERTS.deleteAlertSecure'),
            icon: 'warning',
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            showCancelButton: true,
            backdrop: false,
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no'),
            cancelButtonColor: '#eb3936',
            confirmButtonColor: '#16de4b',
        })
    }


}
