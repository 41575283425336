// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    // apiRoot: 'https://portalempresas.tymap.app/api',
    // apiRoot: 'https://tymap.dot/tymapbackend/web/api',
    apiRoot: 'https://backend.tymap.app/tymapbackend/web/api',
    v2: 'https://backend.tymap.app/tymapbackend/web/v2/',
    // apiRoot: 'http://192.168.1.128/tymap/TymapBackend/web/api',
    //apiRoot: 'https://devphp72.omniumlab.com/tymap/web/api',
    mapsKey:'AIzaSyDdmhvoUqoyEqJVtB0-yQTZWx3FNxvUdeE'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
