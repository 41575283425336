import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Storage} from '@ionic/storage';
import {AlertService} from '../components/alert.service';
import {TranslateService} from '@ngx-translate/core';
import User from 'src/app/interfaces/user.interface';
import {MenuController, NavController} from '@ionic/angular';
import {NotificationService} from '../notification/notification.service';
import {OrderService} from '../order/order.service';
import {GeolocationService} from '../geolocation/geolocation.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    get sessionToken(): string {
        return this._sessionToken;
    }

    set sessionToken(value: string) {
        localStorage.setItem('token', value);
        this._sessionToken = value;
    }


    private user: User = {};
    private userLogin: string = null;

    userEmitter: EventEmitter<User> = new EventEmitter();
    lang = 'es';
    private _sessionToken: string = null;

    constructor(
        private _http: HttpClient,
        private _storage: Storage,
        private _alertService: AlertService,
        private _translateService: TranslateService,
        private _navCtrl: NavController,
        private _menuController: MenuController,
        private notificationService: NotificationService,
        private _geo: GeolocationService,
        private orderService: OrderService) {
        this.init();
    }


    public async validateAuthentication() {
        const response = await Promise.all([this.isAuth(), this.orderService.savedOrder(), this.orderService.getTruck()]);
        if (response[0]) {
            this._navCtrl.navigateForward('home');
            if (response[1] !== null) {
                this._navCtrl.navigateForward('order');
            } else if (response[2] !== null) {
                this._navCtrl.navigateForward(`order/listDriver/${response[2].number}`);
            }
            this.handleMenu();
        }
    }


    // Save credentials
    public saveStorage(user: User) {

        this._storage.set('user', user);
        this._storage.set('userlogin', user.email);
        this.userLogin = user.email;
        this.user = user;
        this.userEmitter.emit(user);
    }


    // Verifies if an user is auth
    public async isAuth(): Promise<boolean> {

        return new Promise<boolean>(async (resolve, reject) => {
            const isAuth = await this._storage.get('user') || null;
            if (isAuth === null) {
                resolve(false)
            } else {
                this.user = isAuth;
                this.userEmitter.emit(this.user);
                resolve(true);
            }
        })


    }

    public handleMenu() {
        if (this.user.rol === 2) {
            this._menuController.enable(false);
        } else if (this.user.rol === null) {
            this._menuController.enable(true);
        } else {
            this._menuController.enable(false);
        }
    }

    // Returns the logged in user
    public getUser() {

        return this.user;
    }

    public async getUserEmail() {

        return await this._storage.get('userlogin');
    }

    public async logOut() {

        this.user = {};
        this.userLogin = await this._storage.get('userlogin');
        this._storage.set('userlogin', this.userLogin);
        this._geo.stopGeolocation()
        this.notificationService.stopListenNotifications()
        this._menuController.enable(false);
        await this._storage.clear();
        localStorage.clear();
        this._navCtrl.navigateRoot('auth/login/signin');
    }


    private async init() {
        this._storage.create();
        this._sessionToken = localStorage.getItem('token');
        this.userLogin = await this._storage.get('userlogin')
    }
}
