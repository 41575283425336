import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalService} from 'src/app/services/modal/modal.service';
import {AlertController, IonSearchbar, MenuController, NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2'
import {Router} from '@angular/router';
import {ApiService} from '../../../services/api/api.service';
import {Storage} from '@ionic/storage';
import { LoadNotificacionService } from 'src/app/services/components/load-notificacion.service';
import { AlertService } from 'src/app/services/components/alert.service';
import { SearchService } from 'src/app/services/search/search.service';
import User from 'src/app/interfaces/user.interface';
import Order from 'src/app/interfaces/order.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Company } from 'src/app/interfaces/company.interface';
import { Menu } from 'src/app/interfaces/menu.interface';
import { OrderService } from 'src/app/services/order/order.service';
import { SidenavMenuService } from 'src/app/services/sidenav-menu/sidenav-menu.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';
import { StorageImageService } from 'src/app/services/storageimage/storage-image.service';
import { OrderListComponent } from '../../modals/order-list/order-list.component';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { TemporizadormapService } from 'src/app/services/temporizadormap/temporizadormap.service';
import { Personalization } from 'src/app/interfaces/personalization.interface';
import { PersonalizationService } from 'src/app/services/personalization/personalization.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    @ViewChild('mySearchbar') searchbar: IonSearchbar;


    // For show hamburguer menu
    @Input() showMenu: boolean = false;
    // For close modals
    @Input() isModal: boolean = false;
    // Back to last page
    //@Input() backTo: string;
    // Handle Order page and clean storage
    @Input() isOrder: boolean = false;

    @Input() withSearch: boolean = false;
    // Login porposes
    @Output() goback: EventEmitter<String> = new EventEmitter();

    isDarkTheme: boolean;

    constructor(
        private alertController: AlertController,
        private _translateService: TranslateService,
        public _modalService: ModalService,
        private _router: Router,
        private _apiService: ApiService,
        private _storageService: Storage,
        private _menuController: MenuController,
        private _nav: NavController,
        private _auth: AuthService,
        private _loader: LoadNotificacionService,
        private _alertService: AlertService,
        private searchService: SearchService,
        private changeDetector: ChangeDetectorRef,
        public _orderService: OrderService,
        private _sideNavService: SidenavMenuService,
        private _languageService: LanguageService,
        private themeService: ThemeServiceService,
        private storageImageS:StorageImageService,
        private localStorageService: LocalStorageService,
        private personalizationService: PersonalizationService,
        private temporrizadorMap: TemporizadormapService) {
          this.themeService.getIsDarkModeObservable().subscribe((isDarkMode) => {
            this.isDarkTheme = isDarkMode;
          });
    }

    public user: User;
    public order: Order;
    order$: Observable<Order>;
    public companies: Company[];
    
    public menuItems: Observable<Menu[]>;
    private menuData: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]) ;
    orderCode: string;
    language: string;


    ngOnInit() {
        this.onLngChange();
        this.loadCachedImage();
        this.storageImageS.imageEmit$.subscribe(image => {
          this.selectedImage = image;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.selectedImageBase64 = e.target.result;
          };
          reader.readAsDataURL(this.selectedImage);

        })
        this._sideNavService.menuChangesSubject$.subscribe((updatedMenuData: Menu[]) => {
          // Actualiza el valor de menuItems con los datos actualizados
          this.menuItems = of(updatedMenuData); // Utiliza 'of' de 'rxjs' para convertir un array en un Observable
      });  
        this.menuItems = this._sideNavService.getMenu(); // Obtiene el menú guardado
        //*****************************QUITADOOO */
        // this.menuItems.subscribe((data) => {
        //   this.menuData.next(data);
        //   this.menuItems = this.menuData.asObservable();
        // }); // Hacemos que el menú sea el que ha guardado
        /***************************QUITADO TODO AU */
        this.order$ = of(null);
        this._sideNavService.orderEmmit$.subscribe(order => {
          this.order = order;
          this.order$ = of(this.order);
        })
        this.user = this._auth.getUser();
        if (this.user.rol === null) {
          this._apiService.getCompanies().subscribe(it => {
            this.companies = it.data.rows
            //console.log(this.companies)
          }, error => {
            console.log(error);
          });
        }


    }

    chargeOrder() {
      const order = localStorage.getItem('currentOrder');
      const currentPage = this._router.url;
      if(order && this.withSearch && currentPage === '/order') {
        this.HandleCodeID(order);
      }
    }

    // Método para obtener el valor de DarkTheme del servicio
   public  esdark(): boolean {
    return this.isDarkTheme;
  }

    /**
     * Handles the navigation in case the user's rol is [1].
     * Handles Login's views.
     * @param none
     * @access public
     * @return Returns void
     */
    public async handleRequest() {

        if (this._auth.getUser() !== null) {
            if (this._auth.getUser().rol === 2 && this._router.url == '/order') {
                this.logOutAlert('ALERTS.EXIT-APP.message');
                return;
            }

            this._nav.pop();

        }

        this.goback.emit('signin')
    }


    public logOutAlert(message: string) {
      const personalization: Personalization = this.personalizationService.getPersonalization();

        Swal.fire({
            text: this._translateService.instant(message),
            showCancelButton: true,
            confirmButtonColor: personalization.primary_color,
            cancelButtonColor: personalization.dark_mode,
            backdrop: false,
            width: 'calc(50vw - 1px)',
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no')
        }).then(async (result) => {
            if (result.value) {
                const token = await this._storageService.get('token');
                this._apiService.logout(token, 1).subscribe(it => {
                })
                this._auth.logOut();
            }
        })

    }

    showMenus() {
        this._menuController.enable(true)
        if(window.location.href.includes('search-order')) {
          this._menuController.open('menuSearch');
        } else {
          this._menuController.open('orderMenu')
        }
    }

    public HandleSearchBox(event:any): void {
        this._loader.alertLoader('ALERTS.loading-message')
        const query = event.target.value.toLowerCase(); // Obtén el valor del campo de búsqueda
        const order: string = query;
        this.findOrderClient(order , true);
        this.searchbar.value = '';
   }

    public HandleCodeID(codeId:string): void {
        this._loader.alertLoader('ALERTS.loading-message')
        const query = codeId.toLowerCase(); // Obtén el valor del campo de búsqueda
        const order: string = query;
        this.findOrderClient(order , true);
        this.searchbar.value = '';
   }

   goOrder() {
    this._router.navigate(['/search-order']);
   }

   public findOrderClient(order:any, saveinMenu: boolean): void {
    
    this._apiService.findOrder(order).subscribe(async it => {

      if (it.data.order.status === 1) {
        await this._loader.stopAlert()
        this._alertService.presentAlertOrderGreatDelivered('ALERTS.INFO-ALERT.delivered', it.data.order.id);
      } else {
        if (!it.data.truck.online) {
          await this._loader.stopAlert()
          await this._alertService.truckNotOnline()
          this.checkExistOrderCode(it.data.order.order_code)
          .then((orderExists) => {
            if (orderExists) {
              this._alertService.presentAlertError( 'ALERTS.replicated-order');
            } 
            else {
              this._orderService.request = it.data.request;
              if(saveinMenu)
              {
                this.addSubMenuToConsultas(it.data.order.order_code);
              }
            }
          })
          .catch((error) => {
            console.error(error); // Manejar errores si es necesario
          });
        } else if (it.data.order.status === 3) {
          await this._loader.stopAlert()
          this._alertService.presentAlertError('ALERTS.noroute');
        } else if (it.data.order.status === 2) {
          await this._loader.stopAlert()
          this._alertService.presentAlertError('ALERTS.nextday');
        } else {
          await this._loader.stopAlert()

        this._sideNavService.removeComany();
        this.user.company = this.companies.find(com => com.id === it.data.order.id_company);
        this._sideNavService.saveCompany(this.user.company);
        this._auth.saveStorage(this.user);
          this.checkExistOrderCode(it.data.order.order_code)
          .then((orderExists) => {
            if (orderExists) {
              this._alertService.presentAlertError( 'ALERTS.replicated-order');
            } 
            else {
              this._orderService.request = it.data.request;
              this._orderService.request_date = it.data.request_date;
              this.order$ = of(it.data.order);
              this._sideNavService.emmitOrderChange(null);
              this._sideNavService.emmitOrderChange(it.data.order);
              if(saveinMenu)
              {
                this.addSubMenuToConsultas(it.data.order.order_code);
              }
              this.localStorageService.putCheckFalse();
              this.temporrizadorMap.stopTracking();
              //clearApiTrucking --> TODO
              this.searchService.setSearchTerm(it.data.order); // Para actualizar automaticamente cuando se pulsa intro
              this._storageService.set('order', it.data.order).then(() => {
                  this.changeDetector.detectChanges(); // Notificar a Angular de los cambios después de completar la operación de almacenamiento
                });
            }
          })
          .catch((error) => {
            console.error(error); // Manejar errores si es necesario
          });
         }
        }
    }
    , error => {
      this._loader.stopAlert()
       if (error.status === 404) {
        this._alertService.presentAlertError('ALERTS.noroute');
      } else {
        this._alertService.presentAlertError('ALERTS.sever_error_title');
      }
    })
     
    }

    public checkExistOrderCode(order_code: string): Promise<boolean> {
      return new Promise((resolve, reject) => {
        const currentMenu = this._sideNavService.getMenu();
        var orderExists: boolean = false;
       
          currentMenu.subscribe((data) => {
            if(data != null)
            {
              this.menuData.next(data);
        
              let consultasItem;
              let ConsultOrQueries: boolean = false;
        
              if (this.language == "en") {
                consultasItem = this.menuData.value.find((item) => item.name === 'Queries');
              } else {
                consultasItem = this.menuData.value.find((item) => item.name === 'Consultas');
              }
        
              const subMenu = consultasItem?.subMenu || [];
              orderExists = subMenu.some(item => item.name === order_code);
              resolve(orderExists);
          }
          else{
            resolve(orderExists);
          }
          }, error => {
            reject(error);
          });
       
        
      });
    }

    addSubMenuToConsultas(query: string) {
        // Obtén el menú actual
        const currentMenu = this.menuData.value;
        var consultasItem;
    
        // Encuentra el elemento "Consultas" en el menú+
        if( this.language == "en")//Ingles
        {
          consultasItem = currentMenu.find((item) => item.name === 'Queries');
        }
        else{
          consultasItem = currentMenu.find((item) => item.name === 'Consultas');
        }
    
        if (consultasItem) {
          // Agrega el submenú a "Consultas" con el nombre de la consulta
          if (!consultasItem.subMenu) {
            consultasItem.subMenu = [];
          }
          consultasItem.subMenu.push({
            name: query,
            icon: "chevron-forward-outline",
            redirectTo: `/order/${query}`, // Ejemplo de redirección personalizada
          });

          this._sideNavService.saveMenu(currentMenu);
  
           // Actualiza el menú con los datos actualizados
          this.menuData.next(currentMenu);
          this._sideNavService.emitMenuChange(currentMenu);
        
        }
    
  
      }

      private async onLngChange() {
        this._languageService.language$.subscribe((lang) => {
            this.language = lang;
            this.menuItems = this._sideNavService.getMenuItems(this.language);
            this.menuItems.subscribe((data) => {
              this.menuData.next(data);
              this.menuItems = this.menuData.asObservable();
            });
          });
      }

    public selectedImage: File;
    public selectedImageBase64: string;

      public async loadCachedImage() {
        const cachedImage = await this.storageImageS.getCachedImage('profileImage');
        if (cachedImage) {
          this.selectedImage = cachedImage;
          // Convierte la imagen a base64 para mostrar el nombre si es necesario
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.selectedImageBase64 = e.target.result;
          };
          reader.readAsDataURL(this.selectedImage);
        }
      }
  

}
