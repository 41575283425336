import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Menu} from '../../interfaces/menu.interface'
import {BehaviorSubject, Observable, Subject, concat, from, of} from 'rxjs';
import {Storage} from '@ionic/storage';

import {concatMap, map, switchMap} from 'rxjs/operators';
import Order from 'src/app/interfaces/order.interface';
import { Company } from 'src/app/interfaces/company.interface';

@Injectable({
    providedIn: 'root'
})
export class SidenavMenuService {

    orderEmmit$: Subject<Order> = new Subject<Order>();
    menuChangesSubject$: Subject<Menu[]> = new Subject<Menu[]>();

    private menuData: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]) ;

    constructor(private _http: HttpClient,
        private storage: Storage) {

    }


    /**
     * @method
     * Return all items of the sidenav menu
     */
    public getMenuItems(lng: string): Observable<Menu[]> {


        return this._http.get<Menu[]>(`/assets/i18n/${lng}.json`).pipe(
            map((response: any) => {
                return response.MENU;
            })
        );
    }

   

    public emitMenuChange(menuData: Menu[]) 
    {
        this.menuChangesSubject$.next(menuData);
    }

    public getMenuChangeObservable(): Observable<Menu[]> 
    {
        return this.menuChangesSubject$.asObservable();
    }

     // Guardar el menú en el almacenamiento local
     public saveMenu(menu: Menu[]): Promise<void> 
     {
         return this.storage.set('menu', menu);
     }

     public getMenu(): Observable<Menu[]> 
     {
        return from(this.storage.get('menu'));
     }

     public emmitOrderChange(order: Order)
     {
        this.orderEmmit$.next(order);
     }

     public async loadMenu(language: string) {
        // Obtiene el idioma actual del almacenamiento local
        const currentLanguage = await this.storage.get('language');
        const currentMenu=this.getMenu();// Menu guardado en storage
        currentMenu.subscribe((data) => {
            this.menuData.next(data);
            var consultasItem;
            var ConsultOrQueries: boolean = false;
            // Verifica si el idioma actual es diferente del idioma cargado previamente
            if (currentLanguage !== language) {
                //Hay que emitir que hay que modificar el nuevo menú
            
                //console.log(this.menuData.value);
                
                if( language == "en")//Ingles
                {
                    consultasItem = this.menuData.value.find((item) => item.name === 'Queries');
                    //console.log("he pasado por aquii")
                    ConsultOrQueries = true;
                }
                else{
                    consultasItem = this.menuData.value.find((item) => item.name === 'Consultas');
                }
                if(consultasItem == undefined)
                {
                    if(ConsultOrQueries == false)// Esta en ingles el menu guardado, pero el idioma que hay que cargar es español
                    {
                        var queries = this.menuData.value.find((item) => item.name === 'Queries');
                    }
                    else //Esta en español el menu guardado, pero el idioma que hay que cargar es ingles
                    {
                        var queries = this.menuData.value.find((item) => item.name === 'Consultas');
                    }
                        const NewMenu=this.getMenuItems(language);// Menu con idioma español

                        if (queries && queries.subMenu) {
                            NewMenu.pipe(
                                switchMap(menuItems => {
                                  const lastMenuArray = menuItems[menuItems.length - 4]; // Supongo que el menú está en el último subarray
                                  
                                  // Encuentra el elemento "Consultas" y "Queries"
                                  const consultasMenuItem = lastMenuArray.name === 'Consultas' ? lastMenuArray : null;
                                  const queriesMenuItem = lastMenuArray.name === 'Queries' ? lastMenuArray : null;
                                    
                                  if (consultasMenuItem || queriesMenuItem) {
                                    // Agrega subMenu de "Queries" a "Consultas"
                                    // if (!consultasMenuItem.subMenu || queriesMenuItem) {
                                    //   consultasMenuItem.subMenu = [];
                                    // }
                                    //console.log("PASO POR AQUI");
                                    if(language == "en")//Ingles
                                    {
                                        queriesMenuItem.subMenu.push(...queries.subMenu);
                                    }
                                    else
                                    {
                                        consultasMenuItem.subMenu.push(...queries.subMenu);
                                    }
                                  }
                              
                                  // Devuelve el menú actualizado
                                  return of(menuItems);
                                })
                              ).subscribe(updatedMenu => {
                                // Aquí puedes trabajar con el menú actualizado
                                this.emitMenuChange(updatedMenu);
                                this.saveMenu(updatedMenu);
                              });
                    }
        }
    }
        });
    }


    // Guardar el menú en el almacenamiento local
    public saveCompany(company: Company): Promise<void> 
    {
        return this.storage.set('company', company);
    }

    public getCompany(): Observable<Company> 
    {
       return from(this.storage.get('company'));
    }

    public removeComany()
    {
        this.storage.remove('company');
    }

    
}
